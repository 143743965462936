
import TableList from "./TableList.vue";
import Buttons from "../../Commons/Buttons.vue";
import SnackBar from "../../Commons/SnackBar.vue";
import Vue from "vue";
import {
	Modifier,
	ModifierFilters,
	ResultPaginate,
} from "../../../../interfaces/modifier";
import { isEmpty, isNull, isUndefined } from "lodash";
// @ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
import ParamService from "../../../../services/params-service";
import { mapGetters, mapActions } from "vuex";
import {
	prepareTableData,
	prepareSelectedData,
	preparedColumnDefsGrid
} from "../../../../utils/CustomizeViewData";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import GridTable from "@/components/Header/Tables/GridTable.vue";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue"
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue"
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue"
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import { getPermisionModifiers } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";

export default Vue.extend({
	name: "ModifiersIndex",
	props: {},
	components: {
		TableList,
		Buttons,
		SnackBar,
		GridTable,
		ActionsTable,
		FilterGridAG,
		ActiveFieldTable,
		NotPermission
	},
	data: () => ({
		title: "Modifiers List",
		paginated: {
			page: 1,
			limit: 25,
		} as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
		table: {
			headers: [],
			items: [],
		},
		fields: {},
		columnRestored: false,
		context: null,
		selectionRows: "single"
	}),
	created() {
		this.$nextTick(async () => {});
	},
	async mounted() {
		this.setLoadingData(TypeLoading.loading)
		await this.getPaginated();
		//await this.dispatchAdvertisers();
		await this.setLoadingData();
		this.context = { componentParent: this };
	},
	computed: {
		...mapGetters("customize_view", [
			"getFieldByView",
			"getSelectedByView",
		]),
		...mapGetters("internationalization", ["getLanguage"]),
		...mapGetters("profile", ["getAbility"]),
		getResultPaginate(): ResultPaginate {
			return this.$store.state.modifier.result_paginate;
		},
		getModifiers(): Modifier[] {
			const result: ResultPaginate = this.getResultPaginate;
			if (
				isUndefined(result) ||
				isNull(result) ||
				isUndefined(result.data) ||
				isNull(result.data)
			) {
				return [];
			}
			return result.data;
		},

		getSizeModifier() {
			return this.$t("show.modifiers", {
				total: this.getModifiers.length,
			});
		},

		getAdvertisers(): any[] {
			return this.$store.state.advertiser.advertisers_list;
		},

		getConfig() {
			return {
				paginated: this.paginated,
				filters: this.filters,
				options: this.options,
				fields: this.fields,
			};
		},

		getConfigColumnDef(){
			return {
				context: this.context,
				eventActive: false,
				entityName: "Modifier",
				redirect: "ModifiersEdit",
				edit: true,
				flex: 1,
				minWidthActions: 80,
				maxWidthActions: 80,
				permission: this.getPermission
			};
		},

		getColumnsDef(){
			return preparedColumnDefsGrid(this.table.headers, this.getConfigColumnDef);
        },

        gerRowsData(){
            if(isEmpty(this.table.items)){
                return [];
            }else{
				return this.table.items;
			}
        },

		canList(){
			return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
		},

		getPermission(){
			return getPermisionModifiers();
		}
	},
	methods: {
		/** Begin Customize View **/
		...mapActions("loading", ["setLoadingData"]),

		...mapActions("customize_view", [
			"setFields",
			"saveSelected",
			"saveColumns",
			"getTableDataSelected",
		]),

		async handleUpdateColumns(event: any) {
			await this.saveSelected(event);
		},

		async handleUpdateSizeColumns(params: any) {
			params.forEach(element => {
				let col = this.table.headers.filter(c => c.value == element.col);
				col[0].reactive_width = element.actualWidth;
				col[0].width = String(element.actualWidth) + "px";
			});
			let event = {
				view: params.entity
			};
			await this.saveColumns({ event, columns: this.table.headers });
		},

		async handleSave(event: any) {
			await this.saveSelected(event);
			await this.saveColumns({ event, columns: this.table.headers });
			await this.getTableData(event);
		},

		async getTableData(
			event: { view: string; isReset: Boolean } = {
				view: "",
				isReset: true,
			}
		) {
			const { selected, fields } = await this.getTableDataSelected({
				route: this.$route?.name,
				entity: this.getModifiers,
			});

			this.fields = fields;

			const headers: Array<any> = selected.columns;
			const columns: Array<any> = await this.getColumns();

			this.table = await prepareTableData({
				columns: columns,
				fields: this.fields,
				entity: "modifier",
				entities: this.getModifiers,
				isReset: event?.isReset,
			});

			if (isEmpty(headers) || event?.isReset) {
				await this.saveColumns({ event, columns: this.table.headers });
				//this.columnRestored = event?.isReset;
			}

			this.$forceUpdate();
		},

		async getColumns() {
			const selected: any = await this.getSelectedByView(
				this.$route?.name
			);
			const columns: Array<any> = selected.columns;
			return columns ?? [];
		},

		/** End Customize View **/

		async getPaginated() {
			if(this.canList){
				this.setLoadingData(TypeLoading.loading)
				await this.$store.dispatch(
					"modifier/paginated",
					await ParamService.getParams(
						this.paginated,
						this.filters,
						this.options
					)
				);
				await this.setLoadingData();
				await this.getTableData({ view: this.$route?.name });
			}
		},

		toFormatDate(d: Date) {
			if (!d) {
				return "";
			}
			// Aquí sugiero que si hay un App Settings
			// tengamos la cadena de formato para fechas y/o Horas establecidas como parámetro global
			return this.moment(d).format("YYYY-MM-DD HH:mm:ss");
		},

		updatePaginate(data: any) {
			this.paginated.page = data;
		},

		setFilter(params: { key: string | number; value: any }) {
			this.filters = {};
			this.filters[params.key] =
				typeof params.value !== "undefined" ? params.value : "";
		},

		async selectedOption(params: {
			options: SortingOption;
			filter: string;
		}) {
			let newKey: string = params.options.sort;

			let newOptions: SortingOption = params.options as SortingOption;

			if (params.options.sort == "advertiser_name") {
				newOptions.sort = "advertiser_id";
			}

			this.setFilter({
				key: newKey,
				value: params.filter,
			});
			this.updatePaginate(1);
			await this.updateParams({
				filters: this.filters,
				options: newOptions,
			});
		},

		async selectedLimit(limit: number) {
			this.paginated.limit = limit;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async updateParams(params: {
			filters: ModifierFilters;
			options: SortingOption;
		}) {
			this.filters = params.filters;
			this.options = params.options;
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async removeOption() {
			this.filters = {};
			this.options = getDefaultSortingOption();
			this.updatePaginate(1);
			await this.getPaginated();
		},

		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" },
			});
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
	},
	watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.getPaginated();
			}
		},
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
